
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































































































$bp: m;

.stacked-pictures,
[class*='stacked-pictures--'] {
  --picture-width: 100%;

  position: relative;
  list-style-type: none;

  @include mq($bp) {
    --picture-width: 40rem;
  }

  @include mq(xl) {
    --picture-width: 65rem;
  }
}

.stacked-pictures__item {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: var(--spacing-m);
  }

  @include mq($bp) {
    padding-left: calc(var(--picture-width) + var(--spacing-l));
  }
}

.stacked-pictures__item__headline {
  @extend %ff-alt;
}

.stacked-pictures__item__title {
  margin: var(--spacing-xs) 0;

  &:first-child {
    margin-top: 0;
  }

  @include mq(l) {
    font-size: 3rem;
  }
}

.stacked-pictures__item__picture-outer {
  width: var(--picture-width);
  margin: var(--spacing-m) 0;

  @include mq($bp) {
    position: absolute;
    top: 0;
    left: var(--wrapper-padding);
    height: 100%;
    margin: 0;

    @for $i from 1 through 10 {
      li:nth-child(#{$i}) & {
        z-index: #{10 - $i};
      }
    }
  }
}

.stacked-pictures__item__picture {
  @extend %border-radius;

  position: relative;
  width: 100%;
  aspect-ratio: 1.5;
  box-shadow: $card-shadow;

  @include mq($bp) {
    --item-top: calc(var(--header-height) + 10vh);
    --item-offset: 0;

    position: sticky;
    top: calc(var(--item-top) + var(--item-offset));
    margin: var(--item-offset) auto 0;

    @for $i from 2 through 10 {
      li:nth-child(#{$i}) & {
        --item-offset: #{($i - 1) * 6rem};

        width: calc(100% - #{($i - 1) * 2rem});
      }
    }
  }
}

.stacked-pictures__item__text {
  order: 1;
}
